<template>
    <div class="order-table">
        <div class="row">
            <div class="col-lg-12">
                <div class="">
                    <div class="d-sm-flex pb-4 align-items-center justify-content-between">
                        <h3 class="mb-2">{{title}}</h3>
                        <div>
                            <b-form-select v-model="defaultStatus" :options="statusOptions"></b-form-select>
                        </div>
                    </div>
                     <div class="order-table-wrap py-2">
                        <div class="row g-3">
                            <template v-for="(order, orIndex) in paginatedOrders">
                                <div @click="$router.push({path: `/orders/${order.id}`})" :key="orIndex" class="col-12 col-md-6 col-lg-12">
                                    <div class="ord-tb-tr p-4 p-lg-5">
                                        <div class="row align-items-lg-center gy-1">
                                            <div class="col-lg-4">
                                                <div class="ord-tb-td" v-if="order.product">
                                                    <div class="mb-2">Number: <strong>#{{order.number}}</strong></div>
                                                    <span class="mr-1">Project:</span> <strong>{{order.product.name}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-lg-2">
                                                <div class="ord-tb-td">
                                                    <span class="mr-1">Buyer:</span> <strong>{{order.buyer.first_name}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-lg-2">
                                                <div class="ord-tb-td">
                                                    <span class="mr-1">Price:</span> <strong>${{order.amount}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-lg-2">
                                                <div class="ord-tb-td">
                                                    <span class="mr-1">Status:</span> <strong class="text-uppercase">{{["active","postpay"].includes(order.status) ? 'active' : order.status}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-lg-2">
                                                <div class="ord-tb-td">
                                                    <span class="btn btn-sm btn-outline-success">view</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <p class="text-center bg-white py-2" v-if="!paginatedOrders.length">No order to show</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <b-pagination
                v-model="paginateOptions.currentPage"
                :total-rows="rows"
                :per-page="paginateOptions.perPage"
                @input="onPageChange"
                class="mt-4"
                >
                </b-pagination>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'order-table',
    data() {
        return {
            defaultStatus:"active",
            paginateOptions:{
                perPage: 4,
                currentPage: 1,
                startIndex: 0,
                endIndex: 4
            },
            statusOptions: [
                { value: 'active', text: 'Active' },
                { value: 'delivered', text: 'Delivered' },
                { value: 'completed', text: 'Completed' },
                { value: 'cancelled', text: 'Cancelled' }
            ]
        };
    },
    props:{
        title:{
            type: String,
            default: "MY ORDERS"
        },
    },
    watch: {
     "$route.query":"refreshQueryStatus"
    },
    computed:{
        orders() {
            return this.defaultStatus == "active"
                  ? this.$store.state.orderList.orders.filter((order) => ['active','postpay'].includes(order.status))
                  : this.$store.state.orderList.orders.filter((order) => order.status == this.defaultStatus)
        },
        rows() {
            return this.orders.length
        },
        paginatedOrders(){
            return this.orders.slice(
                this.paginateOptions.startIndex,
                this.paginateOptions.endIndex
            );
        },
    },
    methods:{
        refreshQueryStatus(){
            if(this.$route.query.status){
                this.defaultStatus = this.$route.query.status
            }
            if(this.status){ this.defaultStatus = this.status}
        },
        onPageChange() {
            this.paginateOptions.startIndex = (this.paginateOptions.currentPage - 1) * this.paginateOptions.perPage;
            this.paginateOptions.endIndex = (this.paginateOptions.currentPage - 1) * this.paginateOptions.perPage + this.paginateOptions.perPage;
        },
    },
    mounted(){
        this.refreshQueryStatus()
    }

}
</script>

<style scoped>


.order-table .order-table-wrap .ord-tb-tr{
    background: #fff;
}
.order-table .order-table-wrap .ord-tb-tr:hover{
    background: #f1f5f7;
    transform: all .5s ease-in-out;
    cursor: pointer;
}

</style>
