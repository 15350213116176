<template>
    <div class="earnings-page py-5 py-lg-6">
        <section v-if="activeUser">
            <div class="row mb-5 gx-4 gy-2 gy-md-3">
                <div class="col-lg-9">
                    <div class="row h-100">
                        <div class="col-lg-12">
                            <div class="card justify-content-center h-100 shadow-sm show-worker-earnings p-5 border">
                                <div class="row g-4 text-center font-weight-bold justify-content-between">
                                    <div class="col-6 col-md-3">
                                        <p class="mb-0">TOTAL INCOME</p>
                                        <h2 class="mb-0 text-success">${{earning.income | money_format}}</h2>
                                    </div>
                                    <div  class="col-6 col-md-3">
                                        <p class="mb-0">WORKER GAIN</p>
                                        <h2 class="mb-0 text-success">${{earning.gain | money_format}}</h2>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <p class="mb-0">WITHDRAWN</p>
                                        <h2 class="mb-0 text-success">${{earning.withdrawn | money_format}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-4">
                    <div class="card text-center shadow-sm show-worker-earnings p-5 border">
                        <p class="mb-0 font-weight-bold">WORKER GAIN</p>
                        <h2 class="text-success">${{earning.gain | money_format}}</h2>
                        <button @click="createTransactionModal = true" class="btn p-2 btn-success">Withdraw</button>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="row pt-5 pt-lg-6">
                <div class="col-12">
                    <order-table title="Completed Orders" status="completed" />
                </div>
            </div>
        </section>

         <section>
            <div class="row pt-5 pt-lg-6">
                <div class="col-12">
                    <transaction-table  />
                </div>
            </div>
        </section>

        <b-modal size="md" title="Withdrawal Request"
            body-bg-variant="light" body-class="p-4"
             hide-footer v-model="createTransactionModal">
                <transaction-form @closeModal="createTransactionModal = false" />
        </b-modal>
    </div>
</template>
<script>

import OrderTable from "@/components/order/OrderTable.vue"
import TransactionForm from "@/components/forms/TransactionForm.vue"
import TransactionTable from "@/components/TransactionTable.vue"

export default {
    name: 'earnings',
    components:{
        OrderTable,
        TransactionForm,
        TransactionTable
    },
    data(){
        return {
            createTransactionModal: false
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
        earning(){
            return this.activeUser.earning
                ? this.activeUser.earning
                : {
                    income: 0,
                    gain: 0,
                    pending: 0,
                    withdrawn: 0,
                  }
        }
    },
    created(){
        this.$store.dispatch("fetchActiveUser");
    }

}
</script>

<style scoped>

.show-worker-earnings h2{
    font-weight: bold;
}

@media(max-width:567px){
    .show-worker-earnings h2{
        font-size:22px;
    }
    .show-worker-earnings p{
        font-size:14px;
    }
}
</style>
