<template>
    <div class="create-transaction">
        <div class="row gx-3">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="AccountName">Account name</label>
                    <input id="AccountName" type="text" class="form-control" v-model="data.account_name"
                    v-validate="'required|alpha_spaces'" placeholder="Account name" name="account_name" />
                    <span class="text-danger font-sm"  v-show="errors.has('account_name')">{{ errors.first('account_name') }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="AccountNumber">Account number</label>
                    <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.account_number"
                    class="form-control" name="account_number" id="AccountNumber" placeholder="Enter account number" />
                    <span class="text-danger invalid-feedback" v-show="errors.has('account_number')">{{ errors.first('account_number') }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="BankName">Bank name</label>
                    <input id="BankName" type="text" class="form-control" v-model="data.bank_name"
                    v-validate="'required|alpha_spaces'" placeholder="Bank name" name="bank_name" />
                    <span class="text-danger font-sm"  v-show="errors.has('bank_name')">{{ errors.first('bank_name') }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="amount">Amount ($)</label>
                    <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.amount"
                    class="form-control" name="amount" id="amount" placeholder="Enter amount" />
                    <span class="text-danger invalid-feedback" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="inputNote">Note</label>
                    <textarea id="inputNote" v-model="data.note" class="form-control" rows="3" placeholder="Write note..." name="note"></textarea>
                </div>
            </div>
            <div>
                 <button type="button" @click.prevent="submitRequest()" class="btn btn-primary mt-2">
                  Send
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components:{
        Multiselect
    },
    data(){
        return {
            data:{
                account_name:"",
                account_number:"",
                bank_name:"",
                amount:"",
                exchange_rate:""
            },
        }
    },
    methods:{
        submitRequest(){
            this.$validator.validateAll().then(result => {
                if (!result) { this.alertError("Form Not Valid"); return;}
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data));
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/transactions/create', formData)
                .then((response)=> {
                    this.$store.dispatch("changeLoaderValue")
                    if(response.data.success){
                        this.$store.commit("transaction/ADD_TRANSACTION", response.data.data.transaction)
                        this.resetForm()
                    }
                })
            });
        },
        resetForm(){
            this.data = {
                account_name:"",
                account_number:"",
                bank_name:"",
                amount:"",
                exchange_rate:""
            }
            this.$emit("closeModal");
            this.$validator.reset()
        },
    },
}
</script>
